/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
 
// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/default.css';
import './styles/menu.css';

// start the Stimulus application
import './bootstrap';
import $ from "jquery";
import 'webpack-jquery-ui/css'
import 'webpack-jquery-ui/draggable'
import 'webpack-jquery-ui/dialog'
//const $ = require('jquery');
//window.$ = $
global.$ = global.jQuery = $;
global.submitForm=function submitForm(b) {
	let form=$(b).parent().find('form')[0];
	event.preventDefault();
	$.ajax({
		url:$(form).attr("action"),
		data:new FormData(form),
		type:'post',
		processData: false,
		contentType: false,
		success: function(data) {
			$('#d-editor').html(data.data);
			}
		});
}
jQuery.fn.swap = function(b) {
	b = jQuery(b)[0];
	var a = this[0],
	    a2 = a.cloneNode(true),
	    b2 = b.cloneNode(true),
	    stack = this;
	
	a.parentNode.replaceChild(b2, a);
	b.parentNode.replaceChild(a2, b);
	
	stack[0] = a2;
	return this.pushStack( stack );
};
$(document).ready(function(){
	$(".menu_entry").click(function(){
		let formname=$(this).attr('id').split('-')[0];
		$.ajax({
			url: '/'+formname+'/list',
			type: 'post',
			dataType: 'json',
			processData: false,
			contentType: false,
			data: {},
			success: function (data) {
				$("#container").html(data.data).promise().done(function(){
					$("[name='edt']").click(function(){
						$.ajax( {
							url: '/'+formname+'/edit/'+$(this).parent().data('rowid'),
							type: 'get',
							dataType: 'json',
							processData: false,
							contentType: false,
							data: {},
							success: function (data) {
									$('#d-editor').html(data.data).promise().done(function(){
											$('#d-editor').removeClass('hidden-edt').addClass('shown-edt');
											$('#d-editor').draggable();
										});
								}
						});
					});
					$(".catalog-block").click(function(){
						$.ajax( {
							url: '/products/catalog_list/'+$(this).parent().data('rowid'),
							type: 'get',
							dataType: 'json',
							processData: false,
							contentType: false,
							data: {},
							success: function (data) {
									$(container).html(data);
								}
						});
					});
					$("[name='deal-buy']").click(function(){
						$.ajax( {
							url: '/'+formname+'/edit/'+$(this).parent().data('rowid'),
							type: 'get',
							dataType: 'json',
							processData: false,
							contentType: false,
							data: {},
							success: function (data) {
									$('#d-editor').html(data.data).promise().done(function(){
											$('#d-editor').removeClass('hidden-edt').addClass('shown-edt');
											$('#d-editor').draggable();
											$("form").submit(function(){
												event.preventDefault();
												$.ajax({
													url:$(this).attr("action"),
													data:new FormData($(this)[0]),
													type:'post',
													processData: false,
													contentType: false,
													success: function(data) {
														$('#d-editor').html(data.data);
													}
												});
												 
											});
										});
								}
						});
					});
				});
			},
			complete: function(data) {
				if (data.status=='200') {

				}
				else {
					alert('erreur');
				}
		}
		} );
	});
});
